<template>
  <v-app fill-height>
    <div class="row flex-column h-100">
      <div class="d-flex flex-column align-items-center mt-5 mb-10">
        <div class="d-flex align-items-center">
          <span class="f13" :class="!this.$vuetify.rtl ? 'visible' : 'invisible'">{{ $t('RIAL') }}</span>
          <span class="mx-2 f24 font-weight-bold">{{ accountBalance.cash | VMask(currencyMask) }}</span>
          <span class="f13" :class="this.$vuetify.rtl ? 'visible' : 'invisible'">{{ $t('RIAL') }}</span>
        </div>
        <span class="f14 mt-4">
          {{ $t('Your balance') }}
        </span>
        <v-btn color="primary" large class="mt-4 rounded-xl" @click="charge()">
          {{ $t('Wallet charge') }} +
        </v-btn>
      </div>

      <div class="bg-white d-flex flex-column grow rounded-t-xl text-dark p-4">
        <span class="f15 fw-bold">{{ $t('Transactions') }}</span>

        <div v-if="accountBalance.details && accountBalance.details.length > 0">
          <v-card class="my-2" v-for="(item, index) in accountBalance.details" :key="index" flat hover ripple
                  color="white">
            <b-list-group-item class="d-flex rounded-xl py-2 ps-2 pe-3 align-items-center">
              <v-avatar v-if="item.status == 'DEPOSIT'" color="green lighten-5 p-2" size="48">
                <iconly type="outline" name="arrow-up" class="green--text"/>
              </v-avatar>
              <v-avatar v-if="item.status == 'WITHDRAW'" color="red lighten-5 p-2" size="48">
                <iconly type="outline" name="arrow-down" class="red--text"/>
              </v-avatar>

              <div class="d-flex flex-column flex-fill ms-2 me-0">
                <div class="d-flex flex-row ">
                  <div class="d-flex flex-row align-items-start justify-content-center">
                    <span class="f13" v-if="item.reason == 'CHARGE_WALLET'">{{ $t('Wallet charge') }}</span>
                    <span class="f13 ms-0 me-2"
                          v-if="item.reason == 'EXAM' && item.doctorFullName">{{ item.doctorFullName }}</span>
                    <span class="f11 primary lighten-5 rounded-pill px-2 py-0 primary--text"
                          v-if="item.reason == 'EXAM'">{{ $t('Exam') }}</span>
                    <span class="f13 ms-0 me-2"
                          v-if="item.reason == 'CONSULTANT' && item.doctorFullName">{{ item.doctorFullName }}</span>
                    <span class="f11 primary lighten-5 rounded-pill px-2 py-0 primary--text"
                          v-if="item.reason == 'CONSULTANT'">{{ $t('Consultant') }}</span>
                  </div>
                  <div class="d-flex ms-auto me-0 align-items-end justify-content-center">
                    <span class="f13 red--text" v-if="item.status == 'WITHDRAW'">- {{
                        item.amount | VMask(currencyMask)
                      }} {{
                        $t('RIAL')
                      }}</span>
                    <span class="f13 green--text" v-if="item.status == 'DEPOSIT'">+ {{
                        item.amount | VMask(currencyMask)
                      }} {{
                        $t('RIAL')
                      }}</span>
                  </div>
                </div>


                <span class="f11 grey--text d-flex flex-row align-items-center mt-1">
                  <i class="far fa-calendar-alt ms-0 me-1"/>
                  {{ item.createDate | moment("dddd , jD jMMMM jYYYY") }}
                  <i class="far fa-clock ms-2 me-1"/>
                  {{ item.createDate | moment("HH:MM") }}
                </span>

                <span class="f11 grey--text mt-1" v-if="item.orderId">{{ $t('Order ID') }}: {{ item.orderId }}</span>
              </div>
            </b-list-group-item>
          </v-card>
        </div>
        <span class="text-center f16 d-block mt-8" v-else>
          {{ $t('No results found') }}
        </span>
      </div>
    </div>

    <vue-bottom-sheet ref="chargeBottomSheet">
      <section class="d-flex flex-column justify-content-center text-dark">
        <span class="my-1 d-block fw-bold text-center f15">{{ $t('Wallet charge') }}</span>

        <span class="my-1 d-block f13">{{ $t('Your payment amount...') }}</span>
        <v-text-field v-mask="currencyMask" class="text-center" outlined light single-line v-model="price"
                      :key="keyPrice" :placeholder="$t('Amount')" :suffix="$t('RIAL')" type="text" :rules="[(v) => !!v]"
                      required
                      hide-details/>

        <div class="my-4 w-100">
          <v-btn block color="primary" @click="submitCharge">{{ $t('Pay') }}</v-btn>
        </div>
      </section>
    </vue-bottom-sheet>


  </v-app>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";

moment.locale("fa", fa);
moment.loadPersian();

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  data() {
    return {
      currencyMask,
      accountBalance: {},
      price: '',
      keyPrice: 0,

      token: '',
    }
  },
  methods: {
    getClientAccountBalance() {
      apiService.getClientAccountBalance()
          .then((response) => {
            this.accountBalance = response.data;
          })
          .catch((err) => {
            console.log(err);
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            });
          });
    },
    charge() {
      this.$refs.chargeBottomSheet.open();
      this.keyPrice++;
      this.price = '';
    },
    submitCharge() {
      if (!this.price) {
        this.$refs.chargeBottomSheet.close();
        this.$toast.error(this.$t("Please enter the amount"), {rtl: this.$vuetify.rtl});
      } else if (this.price.replace(/,/g, '') < 1000) {
        this.$refs.chargeBottomSheet.close();
        this.$toast.error(this.$t("The amount entered must be more than 1000 Toman"), {rtl: this.$vuetify.rtl});
      } else {
        this.$refs.chargeBottomSheet.close();
        this.initiatePayment();
      }
    },
    initiatePayment() {
      var paymentDTO = {
        finalAmount: this.price.replace(/,/g, ''),
        originalAmount: this.price.replace(/,/g, ''),
        paymentReason: 'CHARGE_WALLET',
        reason: 'CHARGE_WALLET'
      };

      apiService.getPaymentRedirectPage(paymentDTO)
          .then((response) => {
            this.token = response.data.token;
            this.$store.commit('update_payment_amount', response.data.amount);
            const form = document.createElement('form');
            form.setAttribute('method', 'post');
            form.setAttribute('action', response.data.redirectUrl);

            const token = document.createElement('input');
            token.setAttribute('type', 'hidden');
            token.setAttribute('name', 'Token');
            token.setAttribute('value', response.data.token);
            form.appendChild(token);

            const getMethod = document.createElement('input');
            getMethod.setAttribute('type', 'text');
            getMethod.setAttribute('name', 'GetMethod');
            getMethod.setAttribute('value', true);
            form.appendChild(getMethod);

            document.body.appendChild(form);
            form.submit();
          })
          .catch((err) => {
            console.log(err);
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            });
          });

    },
    checkPaymentStatus(status) {
      debugger
      if (this.$store.getters.paymentStatus)
        if (status == "SUCCESS") {
          this.$swal({
            icon: "success",
            text: this.$t('Your wallet has been charged successfully'),
            confirmButtonText: this.$t('OK'),
          });
        } else {
          this.$swal({
            icon: "error",
            text: this.$t("Payment failed. If money is deducted from your account, it will be returned to your account within 72 hours"),
            confirmButtonText: this.$t('OK'),
          });
        }
      this.$store.commit('update_payment_status', null);
    }
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

    if (this.$store.getters.paymentStatus) {
      this.checkPaymentStatus(this.$store.getters.paymentStatus);
    }
  },
  beforeDestroy() {
    console.log("beforeDestroy");
    this.$vuetify.theme.dark = false;
  },
  mounted() {
    this.$vuetify.theme.dark = true;
    this.getClientAccountBalance();
  }
}
</script>
